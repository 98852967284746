.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.transition {
  transition: ease-in-out 0.2s all;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pwa-mode .hide-in-pwa {
  display: none;
}

.pwa-mode .show-in-pwa {
  display: block;
}

/* polaris form fixes for tailwind */
.Polaris-TextField__Input {
  background: none;
}

[multiple]:focus, [type=date]:focus, [type=datetime-local]:focus, [type=email]:focus, [type=month]:focus, [type=number]:focus, [type=password]:focus, [type=search]:focus, [type=tel]:focus, [type=text]:focus, [type=time]:focus, [type=url]:focus, [type=week]:focus, input:where(:not([type])):focus, select:focus, textarea:focus {
  box-shadow: none;
}